.App {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f5f5f5;
}

.headline {
  width: 100%;
  padding: 1rem;
  color: rgb(25, 24, 24);
  position: fixed;
  top: 0;
  text-align: center;
}

.headline h1 {
  margin: 0;
  font-size: 2rem;
}

.chat-container {
  display: flex;
  width: 80%;
  height: 80%;
  background-color: white;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.chat-history-container {
  width: 30%;
  height: 100%;
  border-right: 1px solid #ddd;
  display: flex;
  flex-direction: column;
}

.new-chat-button {
  background-color: #0dcaf0;
  color: white;
  border: none;
  padding: 1rem 1rem;
  cursor: pointer;
  font-size: 1.2rem;
  width: 100%;
}

.chat-history {
  flex: 1;
  overflow-y: auto;
}

.chat {
  padding: 1rem;
  cursor: pointer;
}

.chat.selected {
  background-color: #dcd9d9;
}

.chat-ui {
  display: flex;
  flex-direction: column;
  width: 70%;
  height: 100%;
}

.chat-messages {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  padding: 16px;
}

.message {
  margin-bottom: 8px;
  padding: 8px;
  max-width: 90%;
}

.message.user {
  background-color: #DCF8C6;
  color: #000;
  align-self: flex-end;
  border-radius: 7px 7px 0 7px;
}

.message.assistant {
  background-color: #dfdddd;
  color: #000;
  align-self: flex-start;
  border-radius: 7px 7px 7px 0;
}

.chat-input {
  display: flex;
  padding: 1rem;
  border-top: 1px solid #ddd;
}

.chat-input input {
  flex: 1;
  border: none;
  border-radius: 2rem;
  padding: 0.5rem 1rem;
  outline: none;
}

.chat-input button {
  margin-left: 1rem;
  padding: 1rem 2rem;
  background-color: #0dcaf0;
  color: white;
  border: none;
  border-radius: 1.2rem;
  cursor: pointer;
}

.chat-input button:disabled {
  background-color: #ddd;
  cursor: not-allowed;
}

.chat-input textarea {
  flex: 1;
  border: none;
  outline: none;
  resize: none;
  font-size: inherit;
  line-height: 1.5rem;
  font-family: inherit;
}

.code-block {
  display: block;
  background-color: #f5f5f5;
  border-radius: 4px;
  padding: 1rem;
  font-family: monospace;
  overflow: auto;
}

.inline-code {
  background-color: rgb(68, 71, 74);
  font-weight: 800;
  color: rgb(210, 143, 67);
  padding: 0.1em 0.4em;
  border-radius: 4px;
  font-family: monospace;
}

@keyframes dotPulse {
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-50%);
  }

  100% {
    transform: translateY(0);
  }
}

.typing-indicator {
  display: flex;
  align-items: center;
  justify-content: center;
}

.typing-indicator .dot {
  background-color: #000;
  border-radius: 50%;
  width: 6px;
  height: 6px;
  margin: 0 3px;
  animation: dotPulse 1s ease-in-out infinite;
}

.typing-indicator .dot:nth-child(1) {
  animation-delay: -0.2s;
}

.typing-indicator .dot:nth-child(2) {
  animation-delay: 0;
}

.typing-indicator .dot:nth-child(3) {
  animation-delay: 0.2s;
}

.chat-history hr {
  border: none;
  border-top: 1px solid #ccc;
}

.footer {
  margin-bottom: 20px;
  position: fixed;
  bottom: 0;
  text-align: center; 
}

.footer a {
  display: flex;
  align-items: center;
  color: #333;
  text-decoration: none;
}

.footer a:hover {
  text-decoration: underline;
}

.footer .icon {
  margin-right: 5px;
}

/* Add this media query */
@media screen and (max-width: 768px) {
  .App {
    flex-direction: column;
  }
  
  .headline {
    padding: 0.5rem;
  }
  
  .headline h1 {
    font-size: 1.2rem;
  }
  
  .chat-container {
    flex-direction: column;
    width: 100%;
    height: calc(100% - 50px); /* Adjust the height to account for the headline */
    box-shadow: none;
  }
  
  .chat-history-container {
    width: 100%;
    height: 20%;
    border-right: none;
    border-bottom: 1px solid #ddd;
    margin-top: 0.8rem;
  }
  
  .chat-ui {
    width: 100%;
    height: 70%;
  }
  
  .chat-input {
    padding: 0.5rem;
  }
  
  .chat-input button {
    padding: 0.5rem 1rem;
  }
}
